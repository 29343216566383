import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo-removebg-preview.png";
import { useContext } from "react";
import ctx from "../store";
import "animate.css";
import Footer from "./Footer";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiMenu3Fill } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";

const Layout = ({ children, containerRef }) => {
  const { fixedHeader } = useContext(ctx);
  const location = useLocation();
  const [displaySubNav, setDisplaySubNav] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);

  const navigations = [
    {
      title: "home",
      path: "",
    },
    {
      title: "paperlx",
      path: "paperlx",
    },
    {
      title: "spotlx",
      path: "spotlx",
    },
    {
      title: "company",
      subPaths: [
        { title: "about us", path: "company/about-us" },
        { title: "contact us", path: "company/contact-us" },
      ],
    },
    {
      title: "blog",
      path: "blog",
    },
  ];

  const toggleDisplayMenuHandler = () => {
    setDisplayMenu((prevState) => !prevState);
  };

  const closeNavHandler = (e) => {
    if (e.target.dataset.closenav) {
      setDisplayMenu(false);
    }
  };

  return (
    <div className="w-full overflow-hidden relative">
      <header
        className={`w-full  z-50  flex items-center  h-[7.5rem] px-[6rem] transition-all duration-400 ease-in-out sm:px-[2.5rem] ${
          fixedHeader
            ? "fixed top-0 left-0 bg-color-white shadow-md animate__animated animate__fadeIn"
            : "relative bg-color-transparent"
        }`}
      >
        <Link to={""} className="flex items-center">
          <img src={logo} alt="logo" className="h-[4.5rem]" />
          <p className="text-color-purple font-bold text-[2.8rem] ml-[1rem]">
            Techan
          </p>
        </Link>
        <nav
          className={`ml-auto sm:fixed sm:ml-0 sm:w-full sm:flex flex items-center sm:items-start sm:h-screen sm:bg-color-black-transparent top-0  sm:transition-all sm:duration-300 sm:ease-in left-0 f ${
            displayMenu
              ? "sm:translate-x-0 sm:opacity-1"
              : "sm:-translate-x-[100%] sm:opacity-0"
          }`}
          data-closenav="close"
          onClick={closeNavHandler}
        >
          <ul className="flex sm:bg-color-purple   sm:flex-col sm:w-[70%] sm:h-full sm:p-[2rem]">
            {navigations.map((navigation, i) => {
              if (navigation.subPaths) {
                return (
                  <li
                    key={i}
                    className="mr-14 capitalize sm:mr-0"
                    onClick={() => {
                      setDisplaySubNav((prevState) => !prevState);
                    }}
                  >
                    <p
                      className={`flex text-[1.8rem] sm:py-[1rem] items-center font-semibold cursor-pointer ${
                        location.pathname.includes(navigation.title)
                          ? "text-color-purple sm:text-color-grey-3"
                          : "text-color-grey-3 sm:text-color-white sm:hover:text-color-white hover:text-color-grey-4 transition-all duration-200 ease-in"
                      }`}
                    >
                      <span className="mr-[.5rem]">{navigation.title}</span>
                      {!displaySubNav ? (
                        <IoIosArrowDown className={` w-[2rem] h-[2.5rem] `} />
                      ) : (
                        <IoIosArrowUp className={` w-[2rem] h-[2.5rem]  `} />
                      )}
                    </p>
                    <ul
                      className={`absolute top-[6rem] w-[13rem]  bg-color-white border border-color-border  right-[8.5%] z-20 shadow-md overflow-hidden  transition-all duration-300 ease-in-out sm:relative sm:top-0 sm:left-0 sm:w-full sm:right-0 sm:rounded-lg
                      ${
                        displaySubNav
                          ? "opacity-1 translate-y-0 delay-300 visible"
                          : "opacity-0 translate-y-[3rem] hidden"
                      } `}
                    >
                      {navigation.subPaths.map((subPath, i) => (
                        <li key={i}>
                          <NavLink
                            to={subPath.path}
                            className={(activeData) =>
                              activeData.isActive
                                ? `text-color-purple w-full  bg-color-white transition-all duration-100 ease-in font-semibold block py-[1rem] px-[1rem] text-[1.7rem]  text-center sm:text-left`
                                : `text-color-grey-3  text-center border-0 btn-background-right-animation  font-semibold block py-[1rem] px-[1rem] text-[1.7rem] hover:text-color-white transition-all duration-100 ease-in w-full sm:text-left`
                            }
                            data-closenav="close"
                          >
                            {subPath.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li
                    key={i}
                    className={`text-[1.8rem] capitalize ${
                      i === navigations.length - 1 ? "" : "mr-14"
                    }`}
                  >
                    <NavLink
                      to={navigation.path}
                      className={(activeData) =>
                        activeData.isActive
                          ? "text-color-purple sm:text-color-grey-3 sm:py-[1rem] text-[1.8rem] font-semibold flex pb-2 btn-border-animation "
                          : "text-color-grey-3 sm:text-color-white sm:py-[1rem] text-[1.8rem] font-semibold flex pb-2 hover:text-color-grey-4  btn-border-animation"
                      }
                      data-closenav="close"
                    >
                      {navigation.title}
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
          <RxCross1
            className="text-color-white w-[2.5rem] h-[2.5rem] ml-auto mr-[2.5rem] mt-[2.5rem] hidden sm:block"
            onClick={toggleDisplayMenuHandler}
          />
        </nav>
        {!displayMenu && (
          <RiMenu3Fill
            className="w-[2.5rem] h-[2.5rem] text-color-purple hidden sm:block ml-auto"
            onClick={toggleDisplayMenuHandler}
          />
        )}
      </header>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
