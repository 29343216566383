import React, { useState } from "react";
import { GoMail } from "react-icons/go";
import { useForm } from "react-hook-form";
import { registrationOption } from "./utils/formValidation";
import InputComponent from "./InputComponent";
import { user } from "../axiosconfig";
import { toastSuccess, toastError } from "./utils/toastFunctions";
import ReactLoading from "react-loading";

const WaitlistForm = ({ formClass }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const subscribeHandler = async (data) => {
    setIsLoading(true);
    try {
      const res = await user.post("waitlist.json", data.email);

      if (res.statusText !== "OK") {
        throw new Error("Something wrong occured!");
      }
      toastSuccess("Joined waitlist successfully");
      reset({ email: "" });
      setIsLoading(false);
    } catch (err) {
      toastError("Please try again!");
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(subscribeHandler)} className={formClass}>
      <InputComponent
        type="email"
        name="email"
        id="email"
        placeholder="Enter your email"
        inputClassName={`bg-color-white w-full py-[1rem] pl-[4.5rem] pr-[1rem] rounded-lg border border-color-border outline-none focus:bg-color-white ring-0 active:bg-color-white ${
          errors["email"] ? "text-color-red" : "text-color-black"
        }`}
        containerClassName={"relative w-full rounded-lg my-[1.5rem]"}
        errors={errors}
        register={register}
        validation={registrationOption.email}
        icon={
          <GoMail className="absolute top-[1rem] left-[1rem] w-[2.5rem] h-[2.5rem] text-color-border" />
        }
      />
      <button
        type="submit"
        className="bg-color-purple text-color-white w-full py-[1rem] text-center font-bold rounded-lg"
      >
        {isLoading ? (
          <ReactLoading
            type="bubbles"
            color="#fff"
            width={20}
            height={20}
            className="mx-auto"
          />
        ) : (
          "Join Waitlist"
        )}
      </button>
    </form>
  );
};

export default WaitlistForm;
