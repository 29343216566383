import React from 'react'
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

const ContactUsMap = () => {
const position = [6.452667, 7.510333];

    return (
        <div className='w-[80%] sm:w-[90%] mx-auto h-[80rem] sm:h-[70rem] overflow-hidden mt-[3rem] mb-[8rem]'>
            <MapContainer center={position} zoom={12} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.  
                    </Popup>        
                </Marker>
            </MapContainer>
        </div>
    )
}

export default ContactUsMap