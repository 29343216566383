import React, { useContext } from "react";
import ctx from "../../store";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const links = [
  {
    title: "facebook",
    icon: <FaFacebookF className="text-color-current" />,
    link: "https://www.facebook.com",
  },
  { title: "twitter", icon: <FaTwitter />, link: "https://twitter.com/Techan_en?t=1OlzgHDmkVhACXZ5rq9-DA&s=08" },
  {
    title: "linkedIn",
    icon: <FaLinkedinIn />,
    link: "https://www.linkedin.com",
  },
  {
    title: "instagram",
    icon: <FaInstagram />,
    link: "https://instagram.com/techan_en?igshid=ZGUzMzM3NWJiOQ==",
  },
];

const MediaLinks = () => {
  const { fixedHeader } = useContext(ctx);

  return (
    <section
      className={`flex flex-col text-[1.8rem] h-[30rem] sm:h-[50rem] sm:items-center justify-center  w-full px-[10rem] lg:px-[8rem] md:px-[5rem] sm:px-[2.5rem]  ${
        fixedHeader ? "mt-[130rem]" : "mt-[calc(130rem-7.5rem)] "
      }  w-full  bg-color-white`}
    >
      <p className="capitalize text-[4rem] sm:text-[3rem] text-center mb-[3rem] font-bold">
        follow us on
      </p>
      <div className="flex flex-wrap mx-auto sm:grid sm:grid-cols-2 sm:gap-[2rem] ">
        {links.map((link, i) => (
          <a
            key={i}
            target="blank"
            href={link.link}
            className="flex items-center py-[1rem] px-[2rem] rounded-lg border capitalize text-color-purple  bg-color-white border-color-purple hover:text-color-white hover:bg-color-purple transition-all duration-200 ease-in mr-[2rem] last:mr-0 md:mb-[1rem] sm:w-full"
          >
            {link.icon}
            <span className="ml-[1rem]">{link.title}</span>
          </a>
        ))}
      </div>
    </section>
  );
};

export default MediaLinks;
