import React, {
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import ctx from "../store";

const FirstSectionCard = ({ children, className }) => {
  const { setFixedHeader } = useContext(ctx);

  const containerRef = useRef();

  const options = useMemo(() => {
    return {
      root: null,
      threshold: 0,
      rootMargin: "-150px",
    };
  }, []);

  const callBackFn = useCallback(
    (entries, observer) => {
      const [entry] = entries;

      if (!entry.isIntersecting) {
        setFixedHeader(true);
      } else {
        setFixedHeader(false);
      }
      // observer.unobserve(entry.target);
    },
    [setFixedHeader]
  );

  useEffect(() => {
    const current = containerRef.current;
    const observer = new IntersectionObserver(callBackFn, options);

    if (current) observer.observe(current);

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [callBackFn, containerRef, options]);

  return (
    <section
      className={`${className} w-full   flex items-center  `}
      ref={containerRef}
    >
      {children}
    </section>
  );
};

export default FirstSectionCard;
