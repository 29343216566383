import React from "react";
import FirstSectionCard from "../FirstSectionCard";

const IntroSection = () => {
  return (
    <FirstSectionCard className=" blog-bg h-screen absolute top-0 z-10 px-[8rem] smd:px-[5rem] sm:px-[2.5rem] justify-center flex-col">
      <div className="flex flex-col items-center smd:w-full text-color-purple">
        <h2 className="text-[6.5rem] font-bold">Feed</h2>
        <p className="capitalize text-[2.2rem] text-center mt-[1.5rem] w-[80%] md:w-full">
          Get the latest information on crytocurrencys, decentralized autonomous
          organizations and distrubuted networks.
        </p>  
      </div> 
    </FirstSectionCard>
  );
};

export default IntroSection;
