import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsArrowRightShort } from "react-icons/bs";
import sliderImg1 from "../../assets/home/38.png";
import sliderImg2 from "../../assets/home/41.png";
import sliderImg3 from "../../assets/home/61.png";
import shape7 from "../../assets/home/shape7.svg";
import borderImg from "../../assets/home/border.svg";

const howToData = [
  {
    title: "1. Install the App",
    text: "Setup your account and tell us a bit about your employmentand salary so we can tell you how much of your wage you can access today",
    link: false,
  },
  {
    title: "2. Create Account",
    text: "Setup your account and tell us a bit about your employmentand salary so we can tell you how much of your wage you can access today",
    link: false,
  },
  {
    title: " 3. Enjoy the Features!",
    text: "Setup your account and tell us a bit about your employmentand salary so we can tell you how much of your wage you can access today",
    link: true,
  },
];

const HowItWorks = () => {
  const [latestSlide, setLatestSlide] = useState(0);

  const settings = {
    className: "how-it-works",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    afterChange: function (currentSlide) {
      setLatestSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 850,
        settings: {
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  };

  return (
    <section className="flex w-full justify-center my-[8rem]" id="works">
      <div className=" w-[70%] xmd:w-[85%] md:w-full md:pl-[5rem] md:pr-[7rem] relative ">
        <p className="text-[4rem] sm:text-[3rem]  font-bold mb-[3.5rem] smd:text-center">
          How It{" "}
          <span className="relative  ">
            Works
            <img
              src={borderImg}
              alt="border-img"
              className="absolute -bottom-[1rem] right-0 w-full -z-10"
            />
          </span>
        </p>
        <Slider {...settings}>
          {[sliderImg1, sliderImg2, sliderImg3].map((img, i) => (
            <div key={i} className="w-full h-max   justify-between flexed">
              <div className="w-[40%] xmd:w-[50%] sm:w-full">
                {howToData.map((item, i) => (
                  <div key={i} className="">
                    <p
                      className={`font-bold text-[2rem] mb-[.5rem] ${
                        latestSlide === i
                          ? "text-color-black"
                          : "text-[#5d5d66]"
                      }`}
                    >
                      {item.title}
                    </p>
                    <p
                      className={`  ${
                        latestSlide === i
                          ? "block text-color-black"
                          : "hidden text-[#5d5d66]"
                      }`}
                    >
                      {item.text}
                    </p>
                    {item.link && (
                      <Link
                        to=""
                        className={`border-b w-max mt-[.5rem] hover:text-color-purple hover:border-color-purple transition-all duration-200 ease-in flex items-center ${
                          latestSlide === i
                            ? "block text-color-black"
                            : "hidden text-[#5d5d66]"
                        }`}
                      >
                        Learn more{" "}
                        <BsArrowRightShort className="text-color-current" />
                      </Link>
                    )}
                  </div>
                ))}
              </div>
              <div className="w-[40%] relative sm:hidden">
                <img src={img} alt="slider-img" />
                <img
                  src={shape7}
                  alt="shape7"
                  className="absolute bottom-0 -left-[10rem] -z-10"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default HowItWorks;
