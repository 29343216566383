import React from "react";

export default function Appdownloadbtn({ icon, text, onclick, link }) {
  return (
    <a
      className="bg-[#111827] flex py-[1rem] px-[2rem] sm:w-[48%] sm:justify-center   mr-[1.5rem] sm:mr-0 rounded-md  items-center hover:bg-[#212b40]"
      href={link}
      target="_blank"
    >
      <img alt="app" src={icon} className="w-[2.5rem] h-[2.5rem] mr-[1rem]" />
      <div className="flex flex-col items-start text-white text-[1.6rem] text-color-white sm:items-center">
        <p className="text-[1.2rem] uppercase sm:hidden ">Download on the</p>
        <p className="capitalize">{text}</p>
      </div>
    </a>
  );
}
