import React from "react";
import allInApp from "../../assets/home/21.png";
import checkMark from "../../assets/home/checkmark.svg";
import WaitlistForm from "../WaitlistForm";

const offers = [
  "certificates",
  "credentials",
  "identification documents",
  "licences",
  "financial documents",
  "proof of ownership",
];

const ThirdSection = () => {
  return (
    <section className="flex justify-center  sm:px-[2.5rem]">
      <div className="flex w-[80%] sm:w-full justify-between  items-center mx-auto shadow-lg rounded-lg p-[3rem] sm:px-[1.5rem] border border-color-grey">
        <div className="sm:flex sm:flex-col w-[45%] xmd:w-full">
          <h3 className="text-[#1D3A46] sm:text-center text-[4rem] xmd:text-center sm:text-[3.5rem] mb-[1.5rem] font-bold">
            Introducing Paperlx
          </h3>
          <p className="my-[1rem]">
            A better alternative to the current system of paper documents.
          </p>
          {offers.map((offer, i) => (
            <div key={i} className="flex items-center mb-[1rem] capitalize">
              <img src={checkMark} alt="checkMark-icon" className="mr-[1rem]" />
              {offer}
            </div>
          ))}
          <div className="mt-[2rem]" id={"waitlist"}>
            <WaitlistForm formClass="flex flex-col" />
          </div>
        </div>
        <div className="pl-[3rem] w-[45%] xmd:hidden">
          <img src={allInApp} alt="all-in-app" />
        </div>
      </div>
    </section>
  );
};

export default ThirdSection;
