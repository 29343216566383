import React from "react";
import instagramPic from "../../assets/blog/insta1.png";

const BlogInstagramPics = () => {
  return (
    <div>
      <p className="text-[2rem] font-semibold mb-[1rem] capitalize">
        instagram
      </p>
      <div className="grid grid-cols-3 gap-[1rem] xmd:gap-[2rem]">
        {Array.from({ length: 6 }, (_, i) => i + 1).map((key) => (
          <img
            key={key}
            src={instagramPic}
            alt="insta-pic"
            className="rounded-lg w-full"
          />
        ))}
      </div>
    </div>
  );
};

export default BlogInstagramPics;
