import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Paperlx from "./pages/Paperlx";
import Blog from "./pages/Blog";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spotlx from "./pages/Spotlx";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="paperlx" element={<Paperlx />} />
        <Route path="spotlx" element={<Spotlx />} />
        <Route path="company/about-us" element={<AboutUs />} />
        <Route path="company/contact-us" element={<ContactUs />} />
        <Route path="blog" element={<Blog />} />
      </Routes>
      <ToastContainer limit={1} autoClose={2000} />
    </Layout>
  );
}

export default App;
