import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-removebg-preview.png";
import { BsInstagram, BsTwitter} from "react-icons/bs";
import NewsLetter from "./NewsLetter";

const footerData = [
  {
    title: "Company",
    links: [
      { title: "About", path: "/company/about-us" },
      { title: "Contacts", path: "/company/contact-us" },
      { title: "Blogs", path: "/blog" },
    ],
  },
  {
    title: "Paperlx",
    links: [
      { title: "How It Works", path: "/paperlx" },
      { title: "Benefits", path: "/paperlx" },
      { title: "Usecases", path: "/paperlx" },
      // { title: "FAQS ", path: "faqs" },
    ],
  },
];

const mediaLinks = [
  {
    icon: <BsTwitter className="text-color-current text-[2rem]" />,
    path: "https://twitter.com/Techan_en?t=1OlzgHDmkVhACXZ5rq9-DA&s=08",
  },
  {
    icon: <BsInstagram className="text-color-current  text-[2rem]" />,
    path: "https://instagram.com/techan_en?igshid=ZGUzMzM3NWJiOQ==",
  },
  // {
  //   icon: <BsTelegram className="text-color-current  text-[2rem]" />,
  //   path: "https://www.telegram.com",
  // },
];

const Footer = () => {
  return (
    <footer className="flex flex-col bg-[#110211] text-color-white py-[5rem] px-[10rem] xmd:px-[5rem] sm:px-[2.5rem]">
      <div className="flex justify-between sm:flex-wrap ">
        <div className="sm:order-2 flex items-start sm:mt-[4rem]">
          {footerData.map((data, i) => (
            <div
              key={i}
              className={`sm:w-[50%] mr-[10rem] sm:mr-[5rem] ${
                i === 1 ? "sm:order-1" : "sm:order-2"
              }`}
            >
              <p className="font-semibold capitalize mb-[1.5rem] text-[2rem] ">
                {data.title}
              </p>
              <div className="flex flex-col">
                {data.links.map((link, i) => (
                  <Link
                    to={link.path}
                    key={i}
                    className="mb-[1rem] text-[1.7rem] hover:text-color-grey-3 w-max"
                  >
                    {link.title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <NewsLetter />
      </div>
      <div className="border-t border-color-border flex md:flex-wrap items-center justify-between mt-[10rem] pt-[2rem]">
        <Link to={""} className="flex items-center md:order-1">
          <img src={logo} alt="logo" className="h-[4.5rem]" />
          <p className="text-color-white font-bold text-[2.5rem] ml-[1rem]">
            Techan
          </p>
        </Link>
        <div className="flex flex-wrap  items-center md:w-full md:order-3 md:mt-[2rem] md:justify-center sm:justify-end">
          <p className="sm:order-1 sm:mt-[2rem] sm:w-full sm:text-center">
            © 2023 Techan All Rights Reserved
          </p>
          <Link
            to="terms-and-condition"
            className="hover:text-color-grey-3 mx-[2rem]"
          >
            Terms & Conditions
          </Link>
          <Link to="privacy-policy" className="hover:text-color-grey-3">
            Privacy Policy
          </Link>
        </div>
        <div className="flex md:order-2">
          {mediaLinks.map((link, i) => (
            <a
              key={i}
              href={link.path}
              target="blank"
              className="flex w-[3rem] h-[3rem] items-center justify-center border border-color-white rounded-lg hover:text-color-grey-3 hover:border-color-grey-3 mr-[1rem] last:mr-0 "
            >
              {link.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
