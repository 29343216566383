import React, { useEffect } from "react";
import IntroSection from "../components/AboutUs components/IntroSection";
import SecondSection from "../components/AboutUs components/SecondSection";
import CoreValues from "../components/AboutUs components/CoreValues";
import MeetOurTeam from "../components/AboutUs components/MeetOurTeam";
import ReadyToGetStarted from "../components/AboutUs components/ReadyToGetStarted";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div clasName="flex flex-col">
      <IntroSection />
      <SecondSection />
      <CoreValues />
      <MeetOurTeam />
      <ReadyToGetStarted />
    </div>
  );
};

export default AboutUs;
