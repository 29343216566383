import blogPost from "../../assets/blog/postImg.jpg";
import { CgProfile } from "react-icons/cg";
import { MdDateRange } from "react-icons/md";
import formatDate from "../utils/formatDate";

const BlogPostItem = ({ title, text, tag, author, date, img, link }) => {
  const tagsBgColor = {
    developmenttips: "bg-[#171717]",
    productupdate: "bg-[#00b280]",
    casestudy: "bg-[#4d7eff]",
    creative: "bg-[#5c26a0]",
  };

  return (
    <div className="w-[33rem] flex flex-col  rounded-lg overflow-hidden bg-color-white  mb-[4rem] relative xmd:w-[35rem] sm:w-full">
      <img src={img} alt="blog-post" className="w-full h-[20rem]" />
      <p
        className={`absolute z-20 left-[2rem] top-[18rem] px-[1rem] py-[0.5rem] text-color-white w-max uppercase text-[1.5rem] rounded-lg bg-color-purple `}
      >
        {tag}
      </p>
      <div className="p-[2rem] mt-[2rem] flex-1 flex flex-col">
        <a
          href={link}
          target={"blank"}
          className="text-[2.5rem] font-bold block hover:text-color-purple transition-all duration-200 ease-in"
        >
          {title}
        </a>
        <p className="mb-[2rem] mt-[1rem] text-[1.7rem] text-[#6a737c]">
          {text}
        </p>
        <div className="flex items-center justify-between text-[#6a737c] mt-auto text-[1.4rem]">
          <p className="flex items-center">
            <CgProfile className="mr-[.5rem] text-color-current" />
            <span>{author}</span>
          </p>
          <p className="flex items-center">
            <MdDateRange className="mr-[.5rem] text-color-current" />
            <span>{formatDate(date)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPostItem;
