import React from "react";

const InputComponent = ({
  type,
  name,
  id,
  placeholder,
  inputClassName,
  containerClassName,
  validation,
  register,
  label,
  labelClassName,
  icon,
  errors,
}) => {
  return (
    <div className={`flex flex-col ${containerClassName}`}>
      {label && (
        <label htmlFor={id} className={`mb-[1rem]  ${labelClassName}`}>
          {label}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        className={` ${inputClassName}`}
        {...register(name, validation)}
      />
      <>{icon ? icon : ""}</>
      {errors && (
        <small className={`text-color-red `}>
          {errors["email"] && errors["email"].message}
        </small>
      )}
    </div>
  );
};

export default InputComponent;
