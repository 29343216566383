import React from "react";
import FirstSectionCard from "../FirstSectionCard";
import shape1 from "../../assets/paperlx/shape1.svg";
import shape2 from "../../assets/paperlx/shape2.svg";
import shape3 from "../../assets/paperlx/shape3.svg";
import shape4 from "../../assets/paperlx/shape4.svg";
import shape5 from "../../assets/paperlx/shape5.svg";
import shape6 from "../../assets/paperlx/shape6.svg";

const SvgTitle = () => {
  return (
    <div className="absolute -z-10 top-[1rem] right-0">
      <svg
        width="183"
        height="81"
        viewBox="0 0 183 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="draw animatedes"
          d="M12.9677 22.1512C87.33 -23.7497 215.615 14.9169 173.556 52.8019C140.491 82.5856 -7.39191 98.5042 1.51001 44.3796C6.91522 11.5154 133.588 -8.53048 169.449 30.7166"
          stroke="#EFBA34"
        ></path>
      </svg>
    </div>
  );
};

const IntroSection = () => {
  return (
    <FirstSectionCard className=" bg-paperlx h-screen absolute top-0 z-10  justify-center flex-col md:px-[2.5rem]">
      <h1 className="text-[5.5rem] smd:text-[4rem] sm:text-[3rem] text-center font-bold mb-[1.5rem]">
        Authentic Digital Documents{" "}
        <div className="relative w-max mx-auto">
          With Paperlx <SvgTitle />
        </div>
      </h1>
      <p className="text-[1.8rem] smd:px-[2rem] smd:text-center">
        Experience the future with a reliable, and efficient alternative to
        traditional paper documents.
      </p>
      <p className="text-[1.8rem] sm:text-center sm:px-[2rem]">
        Sign Up And Be The First To Know When We Launch
      </p>
      <div className="flex items-end">
        <img src={shape6} alt="shape6" className="mr-[1rem] -ml-[6rem]" />
        <a
          href={"#waitlist"}
          className="text-color-white bg-color-purple block  border-color-purple  hover:bg-color-transparent hover:text-color-purple  mr-[3rem]  font-semibold   py-4  px-10 text-center capitalize border  rounded-lg  transition-all duration-200 ease-in  "
        >
          Join Waitlist
        </a>
      </div>
      <img
        src={shape1}
        alt="shape1"
        className="absolute top-[13rem] left-[13rem] smd:left-[7rem] opacity-70"
      />
      <img
        src={shape2}
        alt="shape2"
        className="absolute top-[33rem] left-[18rem] smd:left-[7rem]  opacity-60"
      />
      <img
        src={shape3}
        alt="shape3"
        className="absolute top-[15rem] right-[48rem] smd:right-[24rem]  opacity-50"
      />
      <img
        src={shape4}
        alt="shape4"
        className="absolute top-[25rem] right-[18rem] smd:right-[9rem] opacity-40"
      />
      <img
        src={shape5}
        alt="shape5"
        className="absolute top-[45rem] right-[38rem] smd:right-[19rem] opacity-40"
      />
    </FirstSectionCard>
  );
};

export default IntroSection;
