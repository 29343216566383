import React, { useEffect } from "react";
import IntroSection from "../components/Paperlx components/IntroSection";
import UsesSec from "../components/Paperlx components/UsesSec";
import HowItWorks from "../components/Home components/HowItWorks";
import VideoSection from "../components/Paperlx components/VideoSection";
import NextGenSec from "../components/Paperlx components/NextGenSec";
import FeaturesSec from "../components/Paperlx components/FeaturesSec";
import WaitlistForm from "../components/WaitlistForm";
// import ReadUpOnLatet from "../components/Paperlx components/ReadUpOnLatest";

const Paperlx = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <IntroSection />
      <UsesSec />
      <div
        className="w-[70rem] px-[4rem] py-[2rem] border border-color-border rounded-lg my-[8rem] mx-auto smd:w-[80%] sm:w-[90%] sm:px-[2rem]"
        id="waitlist"
      >
        <WaitlistForm formClass="flex flex-col" />
      </div>
      <VideoSection videoClass={"w-[70%] h-[50rem] sm:w-[90%]"} />
      <NextGenSec />
      <FeaturesSec />
      <HowItWorks />
      {/* <ReadUpOnLatest /> */}
    </>
  );
};

export default Paperlx;
