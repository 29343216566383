import React, { useState, useEffect } from "react";
import BlogPostItem from "./BlogPostItem";
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import BlogPostItemSkeleton from "./BlogPostItemSkeleton";
import { user } from "../../axiosconfig";

const BlogPostList = () => {
  // const { blogPostsList, blogCategory } = useContext(ctx);

  // const list = blogCategory
  //   ? blogPostsList.filter(
  //       (post) => post.tag.toLowerCase() === blogCategory.toLowerCase()
  //     )
  //   : blogPostsList;

  const [itemOffset, setItemOffset] = useState(0);
  const [loading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const itemsPerPage = 4;

  const endOffset = itemOffset + itemsPerPage;
  let currentItems = posts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(posts.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % posts.length;
    setItemOffset(newOffset);
  };

  const paginateNavStyle =
    "block  bg-color-white w-[3rem] h-[3rem]  flex items-center justify-center  rounded-lg  border   transition-all duration-200 ease-in";

  const getPostsHandler = async () => {
    let data;
    try {
      const response = await user.get("blogData.json");

      for (const key in response.data) {
        data = response.data[key];
        setPosts(data);
      }
      setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getPostsHandler();
  }, []);

  return (
    <>
      {loading && (
        <div className="w-full flex flex-wrap justify-between sm:items-center ">
          <BlogPostItemSkeleton /> <BlogPostItemSkeleton />
          <BlogPostItemSkeleton /> <BlogPostItemSkeleton />
        </div>
      )}

      <div className="w-full flex flex-wrap justify-between sm:items-center ">
        {currentItems &&
          currentItems.map((post, i) => (
            <BlogPostItem
              key={i}
              title={post.title}
              tag={"News"}
              text={post.text}
              author={post.author}
              date={post.date}
              img={post.img}
              link={post.link}
            />
          ))}
      </div>
      {currentItems && (
        <div className="flex mt-[2rem] px-[2rem] sm:px-0">
          <ReactPaginate
            breakLabel="..."
            nextLabel={<MdKeyboardArrowRight className="text-color-current " />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={
              <MdKeyboardArrowLeft className="text-color-current" />
            }
            renderOnZeroPageCount={null}
            containerClassName="flex items-center flex-wrap w-full"
            previousClassName="mr-[1rem] sm:mb-[1rem] "
            nextClassName="ml-[1rem] sm:mb-[1rem]"
            previousLinkClassName={paginateNavStyle}
            nextLinkClassName={paginateNavStyle}
            pageLinkClassName="paginate-page-link"
            activeLinkClassName="paginate-active-page-link"
          />
        </div>
      )}
    </>
  );
};

export default BlogPostList;
