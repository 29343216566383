import React, { useEffect } from "react";
import ContactUsContainer from "../components/ContactUs components/ContactUsContainer";
import MediaLinks from "../components/ContactUs components/MediaLinks";
import ContactUsMap from "../components/ContactUs components/ContactUsMap";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContactUsContainer />
      <MediaLinks />
      <ContactUsMap />
    </>
  );
};

export default ContactUs;
