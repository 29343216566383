import feaImg from "../../assets/aboutus/53.png";
import { Link } from "react-router-dom";

const ReadyToGetStarted = () => {
  return (
    <section className="py-[10rem] px-[10rem] xlg:px-[8rem] xmd:px-[5rem] sm:px-[2.5rem] smd:flex-col w-full flex items-center justify-between">
      <div className="w-[40%] xlg:w-[45%] smd:w-full flex flex-col smd:items-center smd:mb-[5rem] smd:text-center">
        <p className="text-[4rem] sm:text-[3rem] font-bold">
          Ready to get started?
        </p>
        <p className="my-[1rem] text-[1.8rem]">
          Whether you're an individual, a small business owner, or a large
          corporation looking to stay ahead of the curve, Techan is committed to
          providing you with the tools to succeed in the digital age.
        </p>
        <Link
          className="bg-color-purple text-color-white border-color-purple border py-[1rem] px-[2rem] capitalize rounded-lg block font-semibold hover:bg-color-white hover:text-color-purple transition-all duration-200 ease-in w-max"
          to="/paperlx"
        >
          our products
        </Link>
      </div>
      <div className="w-[40%] xlg:w-[45%] smd:w-[75%] sm:w-[85%]">
        <img src={feaImg} alt="img" className="w-full h-full" />
      </div>
    </section>
  );
};

export default ReadyToGetStarted;
