import React, { useState } from "react";
import FirstSectionCard from "../FirstSectionCard";
import formImg from "../../assets/contactus/form-icon.svg";
import ContactUsForm from "./ContactUsForm";

const ContactUsContainer = () => {
  const [contactType, setContactType] = useState("general");

  return (
    <FirstSectionCard className=" bg-[#ebfbff]  h-[130rem] py-[12rem] absolute top-0 z-10   justify-center flex-col">
      <p className="text-[#616161] font-semibold mb-[2rem] sm:mb-[1rem]">
        GET IN TOUCH
      </p>
      <p className="font-bold text-color-black text-[4rem] sm:text-[3rem] mb-[1rem]">
        How can we help you
      </p>
      <p className="text-[#616161] text-[1.8rem] mb-[4rem] flex flex-col text-center items-center smd:px-[2rem]">
        <span> We’re here to help and answer any question you might have.</span>
        <span>We look forward to hearing from you.</span>
      </p>
      <div className="w-[50%] xl:w-[60%] lg:w-[70%] xmd:w-[80%] md:w-[85%] smd:w-[90%] bg-color-white  p-[4rem] sm:px-[2rem]  rounded-lg shadow-contact-us-form-container">
        <div className="bg-[#f2f4ff] p-[1rem] rounded-lg flex items-center justify-between">
          {["general", "business", "partnership"].map((item, i) => (
            <p
              key={i}
              className={` text-[1.8rem] py-[1rem] px-[2rem] cursor-pointer hover:text-color-purple text-color-black capitalize ${
                contactType === item
                  ? "bg-color-white shadow-contact-us-nav"
                  : "bg-color-transparent "
              }`}
              onClick={() => {
                setContactType(item);
              }}
            >
              {item}
            </p>
          ))}
        </div>
        <div className="flex flex-col items-center mt-[4rem] mb-[2rem]">
          <img src={formImg} alt="form-img" />
          <p className="my-[1rem] capitalize font-bold text-[2.5rem]">
            <span>{contactType}</span> Contact
          </p>
          <p className="text-[#616161] sm:text-center">
            Have some feedback or a <span>{contactType}</span> question? Get in
            touch with us below
          </p>
        </div>
        {/* form */}
        <ContactUsForm contactType={contactType} />  
      </div>
    </FirstSectionCard>
  );
};

export default ContactUsContainer;
