import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BlogPostItemSkeleton = () => {
  return (
    <div className=" w-[33rem] mb-[4rem] relative xmd:w-[35rem] sm:w-full">
      <SkeletonTheme baseColor="#E0E0E0" highlightColor="#eee">
        <div>
          <Skeleton count={1} className="h-[20rem] " />
        </div>
        <p className="my-[2rem]">
          <Skeleton count={2} className={` h-[3rem] `} />
        </p>
        <p>
          <Skeleton count={4} className={` h-[2rem] `} />
        </p>

        <div className="flex justify-between items-center mt-[3rem]">
          <p className="w-[30%]">
            <Skeleton count={1} className={` h-[2rem] w-[30%]`} />
          </p>
          <p className="w-[30%]">
            <Skeleton count={1} className={` h-[2rem] w-[30%]`} />
          </p>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default BlogPostItemSkeleton;
