import React, { useContext } from "react";
import ctx from "../../store";
import featureImg1 from "../../assets/paperlx/fea-images1.svg";
import featureImg2 from "../../assets/paperlx/fea-images2.svg";
import featureImg3 from "../../assets/paperlx/fea-images3.svg";
import featureImg4 from "../../assets/paperlx/fea-images4.svg";
import feaShape from "../../assets/paperlx/fea-images-shape1.svg";
import feaShape2 from "../../assets/paperlx/fea-images-shape2.svg";
import feaShape3 from "../../assets/paperlx/fea-images-shape3.svg";
import feaShape4 from "../../assets/paperlx/fea-images-shape4.svg";
import feaShape1 from "../../assets/paperlx/fea-shape1.svg";

const usesData = [
  {
    sub: "AUTHENTICATION",
    title: "Authenticate Documents with Digital Signatures",
    text: "Paperlx uses digital signatures to ensure your documents are authentic and secure. Our immutable digital signatures provide an unmatched level of security and trust, giving you confidence in the authenticity of digital documents.",
    img: featureImg1,
    img2: featureImg2,
  },
  {
    sub: "VERIFICATION",
    title: "Verify the Authenticity of Digital Documents",
    text: "Quickly and easily verify the authenthicity of digital documents with Paperlx's streamlined verification process. Eliminating the risk of Fraud, cost and time-consuming paper-based verification processes.",
    img: featureImg3,
  },
  {
    sub: "SHARING",
    title: "Send and Receive Authentic Digital Documents",
    text: "Paperlx provides a cheaper, secure and convenient system to share authentic digital documents instantly across the globe. Eliminating the cost and time-consuming paper-based verification processes",
    img: featureImg4,
  },
];

const UsesSec = () => {
  const { fixedHeader } = useContext(ctx);

  return (
    <section
      className={`flex flex-col text-[1.8rem]  w-full  ${
        fixedHeader ? "mt-[100vh]" : "mt-[calc(100vh-7.5rem)]"
      }  w-full  bg-color-white`}
    >
      {usesData.map((use, i) => (
        <div
          key={i}
          className={`w-full flex items-center justify-between px-[8rem]  xmd:px-[5rem] sm:px-[2.5rem] py-[12rem] xlg:flex-col ${
            i % 2 === 0 ? "" : "bg-paperlx"
          }`}
        >
          <div
            className={`w-[40%] xl:w-[45%] xlg:w-full xlg:text-center xlg:mb-[7rem] ${
              i % 2 === 0 ? "order-1" : "order-2 xlg:order-1"
            }`}
          >
            <p className="text-[#616161] text-[1.5rem] mb-[1.5rem]">
              {use.sub}
            </p>
            <h2 className="font-bold text-[4rem] sm:text-[3rem] sm:w-full leading-[4.5rem] mb-[2rem]">
              {use.title}
            </h2>
            <p className="sm:w-full">{use.text}</p>
          </div>
          <div
            className={`w-[45%] xlg:w-[80%] xlg:justify-center xlg:mx-auto   flex relative ${
              i % 2 === 0 ? "order-2 " : "order-1 xlg:order-2"
            }`}
          >
            <img src={use.img} alt={use.sub} className="z-20" />
            {use.img2 && (
              <img
                src={use.img2}
                alt={use.sub}
                className="ml-[2rem] mt-[10rem] xl:ml-[1rem] sm:hidden"
              />
            )}
            {i === 0 && (
              <>
                <img
                  src={feaShape2}
                  alt="fea-img"
                  className="ml-[2rem] xl:hidden"
                />
                <div className="w-[1.4rem] h-[1.4rem] bg-[#c6c6c6] absolute top-[4rem] left-[70%] rotate-45  sm:-top-[2rem]"></div>
                <div className="w-[1.4rem] h-[1.4rem] bg-color-transparent border border-[#c6c6c6]  sm:-top-[1rem] absolute top-[6rem] left-[65%] rotate-45"></div>
              </>
            )}
            {i === 1 && (
              <>
                <img
                  src={feaShape3}
                  alt="fea-img"
                  className="absolute top-0 left-0"
                />
                <img
                  src={feaShape1}
                  alt="fea-img"
                  className="absolute top-[45%] z-10 -right-[3.5rem] "
                />
                <img
                  src={feaShape2}
                  alt="fea-img"
                  className="absolute top-[26%] right-[5rem] z-10"
                />
                <div className="w-[14.8rem] h-[14.8rem] border-[1.5rem] rounded-[50%] left-[40%] -top-[10%] border-[#fff6d7] absolute"></div>
              </>
            )}
            {i === 2 && (
              <>
                <img
                  src={feaShape1}
                  alt="fea-img"
                  className="absolute -top-[2rem] z-10 -right-[3.5rem] "
                />
                <img
                  src={feaShape4}
                  alt="fea-img"
                  className="absolute -bottom-[2rem] z-10 left-[2rem]"
                />
                <img
                  src={feaShape}
                  alt="fea-img"
                  className="absolute -bottom-[2rem] z-10 right-[2rem] "
                />
              </>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};

export default UsesSec;
