import React from "react";
import FirstSectionCard from "../FirstSectionCard";
import Button from "../ButtonComponent";
import VideoSection from "../Paperlx components/VideoSection";
import { Link } from "react-router-dom";

const IntroSection = () => {
  return (
    <FirstSectionCard className=" bg-aboutus h-screen absolute top-0 z-10  justify-between px-[10rem] xl:px-[8rem] xmd:flex-col xmd:justify-center xmd:h-[120rem] smd:px-[5rem] sm:px-[2.5rem]">
      <div className="w-[40%] xl:w-[45%] xmd:w-full xmd:text-center xmd:flex xmd:flex-col xmd:items-center">
        <h2 className="text-[3.5rem] sm:text-[3rem] font-bold">
          Empowering Progress and Innovation Through Digital Transformation
        </h2>
        <p className="my-[1.5rem] text-[1.8rem]">
          We empower individuals and organizations to embrace new possibilities
          and pioneer the future of digital advancement.
        </p>
        <div className="capitalize mt-[4rem]">
          <a
            href={"https://www.youtube.com"}
            target="blank"
            className="border-black border-2 text-color-black bg-color-white hover:bg-color-black hover:text-color-white mr-[3rem] py-4  px-10 text-center capitalize  rounded-lg  transition-all duration-200 ease-in"
          >
            watch demos
          </a>
          <Link
            to={"/paperlx"}
            className="border-color-purple bg-color-purple border-2 text-color-white hover:bg-color-white hover:text-color-purple  py-4  px-10 text-center capitalize  rounded-lg  transition-all duration-200 ease-in  "
          >
            Explore products
          </Link>
        </div>
      </div>
      <div className="w-[40%] relative xl:w-[45%] xmd:w-full">
        <VideoSection videoClass={"w-[90%] h-[40rem] z-20"} />
        <div className="absolute bg-[#bfe7db] rounded-lg w-[80%] h-[40rem] top-[3.5rem] right-0"></div>
      </div>
    </FirstSectionCard>
  );
};

export default IntroSection;
