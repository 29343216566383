import React, { useEffect } from "react";
import IntroSection from "../components/spotlx components/IntroSection";
import SecondSection from "../components/spotlx components/SecondSection";

const Spotlx = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <IntroSection />
      <SecondSection />
    </>
  );
};

export default Spotlx;
