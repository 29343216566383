import React from "react";

const Button = ({ children, className, clickFunction }) => {
  return (
    <button
      className={`${className}  py-4  px-10 text-center capitalize border  rounded-lg  transition-all duration-200 ease-in  `}
      onClick={clickFunction}
    >
      {children}
    </button>
  );
};

export default Button;
