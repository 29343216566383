import React from "react";
import feaImg1 from "../../assets/aboutus/60.png";
import feaImg2 from "../../assets/aboutus/77.png";
import feaImg3 from "../../assets/aboutus/72.png";
import feaShape2 from "../../assets/aboutus/fea-shape2.png";
import feaShape4 from "../../assets/aboutus/fea-shape4.png";
import feaShape6 from "../../assets/aboutus/fea-shape6.png";

const ImageBox = ({ type, index }) => {
  const mainImgClassName =
    "rounded-lg  h-[40rem] z-20 relative bg-color-white shadow-fea-aboutus";



  const backImgClassName = ` absolute top-0 z-10 w-[80%] ${
    index % 2 ? "-left-[4rem]" : "-right-[4rem]"
  }`;

  return (
    <div className="relative w-full ">
      {type === "vision" && (
        <>
          <img src={feaImg1} alt="fea-img-1" className={mainImgClassName} />
          <img src={feaShape2} alt="fea-shp-2" className={backImgClassName} />
        </>
      )}
      {type === "mission" && (
        <>
          <img src={feaImg2} alt="fea-img-2" className={mainImgClassName} />
          <img src={feaShape4} alt="fea-shp-4" className={backImgClassName} />
        </>
      )}
      {type === "goal" && (
        <>
          <img src={feaImg3} alt="fea-img-3" className={mainImgClassName} />
          <img src={feaShape6} alt="fea-shp-6" className={backImgClassName} />
        </>
      )}
    </div>
  );
};

export default ImageBox;
