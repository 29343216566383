import ctx from "../../store";
import { useContext } from "react";
import ImageBox from "./ImageBox";

const featuresData = [
  {
    tag: "vision",
    title: "Revolutionizing the Digital Landscape",
    text: "At Techan, our vision is to be at the forefront of digital innovation and to lead the charge in advancing the digital landscape. We aim to revolutionize traditional systems and processes across different sectors through our cutting-edge tools and solutions.",
  },
  {
    tag: "mission",
    title: "Facilitate Digital Evolution",
    text: "Our mission is to empower individuals and organizations to embrace digital transformation and leverage new technologies to drive progress and innovation. We achieve this through the development of innovative tools and solutions that streamline workflows, improve processes, and enhance productivity.",
  },
  {
    tag: "goal",
    title: "Make a Positive Impact On The World",
    text: "Our ultimate Goal is to create a world where everyone has access to the opportunities and possibilities of the digital landscape, and where digital innovation is a catalyst for positive change and progress.",
  },
];

const SecondSection = () => {
  const { fixedHeader } = useContext(ctx);

  return (
    <section
      className={` flex flex-col text-[1.8rem] py-[12rem] xlg:py-[10rem] xmd:py-[8rem] sm:py-[6rem] px-[10rem] xmd:px-[5rem] sm:px-[2.5rem]  w-full  ${
        fixedHeader
          ? "mt-[100vh] xmd:mt-[120rem]"
          : "mt-[calc(100vh-7.5rem)] xmd:mt-[calc(120rem-7.5rem)]"
      }  w-full  bg-color-white`}
    >
      {featuresData.map((feature, i) => (
        <div
          key={i}
          className="flex items-center justify-between text-[#616161] mb-[12rem] last:mb-[8rem] smd:flex-col smd:mb-[8rem] smd:last:mb-[5rem]"
        >
          <div
            className={`w-[40%] xlg:w-[45%] smd:w-full sm:text-center ${
              i % 2 === 0 ? "order-1" : "order-2 smd:order-1"
            }`}
          >
            <p className=" text-[1.7rem] uppercase">our {feature.tag}</p>
            <p className="mt-[1.5rem] mb-[1rem] text-color-black text-[3rem] font-bold ">
              {feature.title}
            </p>
            <p className="text-[1.8rem]">{feature.text}</p>
          </div>
          <div
            className={`w-[40%] xlg:w-[45%] smd:w-[70%] sm:w-[85%] smd:mt-[5rem] ${
              i % 2 === 0 ? "order-2 " : "order-1 smd:order-2"
            }`}
          >
            <ImageBox type={feature.tag} index={i} />
          </div>
        </div>
      ))}
    </section>
  );
};

export default SecondSection;
