import { toast } from "react-toastify";
import { BsFillPatchExclamationFill } from "react-icons/bs";
import { BsPatchCheckFill } from "react-icons/bs";

export const toastError = (msg) => {
  toast.error(msg, {
    hideProgressBar: true,
    autoClose: 2000,
    icon: (
      <BsFillPatchExclamationFill className="text-color-red w-[2rem] h-[2rem] " />
    ),
  });
};

export const toastSuccess = (msg) => {
  toast.success(msg, {
    hideProgressBar: true,
    autoClose: 2000,
    icon: <BsPatchCheckFill className="text-color-purple w-[2rem] h-[2rem] " />,
  });
};
