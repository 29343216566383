import React from "react";
import outFeatureImg1 from "../../assets/paperlx/out-feature-1.svg";
import outFeatureImg2 from "../../assets/paperlx/out-feature-2.svg";
import outFeatureImg3 from "../../assets/paperlx/out-feature-3.svg";
import outFeatureImg4 from "../../assets/paperlx/out-feature-4.svg";

const features = [
  {
    img: outFeatureImg4,
    title: "Authentic",
    text: "Enabling easily verifiable authenticity and reliable information",
  },
  {
    img: outFeatureImg2,
    title: "Secure",
    text: "Bolstering security through robust data protection measures",
  },
  {
    img: outFeatureImg3,
    title: "Efficient",
    text: "Streamlining processes, reducing costs and boosting productivity",
  },
  {
    img: outFeatureImg1,
    title: "Accessibile",
    text: "Ensuring always-on availability and uninterrupted access",
  },
];

const FeaturesSec = () => {
  return (
    <section className="my-[8rem] py-[4rem] px-[10rem]">
      <div className="grid grid-cols-paperlx-features-grid gap-[3rem] ">
        {features.map((feature, i) => (
          <div key={i} className="flex flex-col items-center text-center">
            <img src={feature.img} alt={feature.title} />
            <p className="text-[2rem] font-semibold mt-[2rem] mb-[1rem]">
              {feature.title}
            </p>
            <p className="text-[#616161] text-[1.8rem]">{feature.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturesSec;
