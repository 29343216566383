import React from "react";
import banner4 from "../../assets/home/banner-shape-4.svg";
import banner5 from "../../assets/home/banner-shape-5.svg";
import imgGif from "../../assets/home/Home-Text.gif";
import worldGif from "../../assets/home/world.png";
import Button from "../ButtonComponent";
import FirstSectionCard from "../FirstSectionCard";
import { Link } from "react-router-dom";

const FirstSection = () => {
  return (
    <FirstSectionCard className="h-screen relative  py-[4rem] px-[8rem] xmd:flex-col xmd:h-max md:px-[5rem] sm:px-[2.5rem]">
      <img
        src={banner5}
        alt="banner-5"
        className="absolute -top-[1.5rem] z-10  right-0 opacity-20"
      />
      <img
        src={banner4}
        alt="banner-4"
        className="absolute -bottom-[2rem] left-0  opacity-20"
      />
      <div className="w-[50%] xmd:w-full xmd:flex xmd:flex-col xmd: items-center xmd:text-center xmd:my-[3rem]">
        <img src={imgGif} alt="img-gif" className="z-20" />
        <p className="text-[1.8rem] my-[1.5rem] xmd:w-[80%] smd:w-[90%]">
          Building tools to drive digital advancement across different sectors
          and transform traditional systems on a global scale.
        </p>
        <div className="flex mt-14">
          <a
            href={"#waitlist"}
            className="text-color-white bg-color-purple block border-color-purple  hover:bg-color-white hover:text-color-purple  mr-[3rem]    py-4  px-10 text-center capitalize border  rounded-lg  transition-all duration-200 ease-in   "
          >
            Join Waitlist
          </a>
          <Link
            to={"/blog"}
            className="text-color-purple block bg-color-white  border-color-purple hover:bg-color-purple hover:text-color-white   py-4  px-10 text-center capitalize border  rounded-lg  transition-all duration-200 ease-in  
          "
          >
            Learn more
          </Link>
        </div>
      </div>
      <img
        src={worldGif}
        alt="world-gif"
        className="w-[50%] xmd:w-[60%] smd:w-[70%] sm:w-[85%]"
      />
    </FirstSectionCard>
  );
};

export default FirstSection;
