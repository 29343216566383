import React, { useEffect } from "react";
import FirstSection from "../components/Home components/FirstSection";
import SecondSection from "../components/Home components/SecondSection";
import ThirdSection from "../components/Home components/ThirdSection";
import FourthSection from "../components/Home components/FourthSection";
import HowItWorks from "../components/Home components/HowItWorks";
import ReadUpOnLatest from "../components/Home components/ReadUpOnLatest";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <HowItWorks />
      {/* <ReadUpOnLatest /> */}
    </>
  );
};

export default Home;
