import img1 from "../../assets/aboutus/component-1.svg";
import img2 from "../../assets/aboutus/component-2.svg";
import img3 from "../../assets/aboutus/component-3.svg";
import img4 from "../../assets/aboutus/component-4.svg";
import img5 from "../../assets/aboutus/component-5.svg";
import img6 from "../../assets/aboutus/component-6.svg";

const valuesData = [
  {
    image: img1,
    title: "Innovation",
    text: "We embrace creativity and seek out new and innovative solutions to challenges.",
  },
  {
    image: img2,
    title: "Excellence",
    text: "We strive for excellence in every aspect of our work, from development to customer service.",
  },
  {
    image: img3,
    title: "Teamwork",
    text: "We value teamwork and collaboration, recognizing that our best work comes from working together",
  },
  {
    image: img4,
    title: "Social Responsibility",
    text: "We are committed to making a positive impact on the world, through our work and actions as a company",
  },
  {
    image: img5,
    title: "Continuous Learning",
    text: "We are committed to lifelong learning and growth, and encourage our team to pursue new skills and knowledge.",
  },
  {
    image: img6,
    title: "Adaptability",
    text: "We are able to adapt to changing technologies, trends, and customer needs, and stay ahead of the curve.",
  },
];

const CoreValues = () => {
  return (
    <section className="mb-[4rem] flex flex-col items-center px-[10rem] xmd:px-[8rem] smd:px-[5rem] sm:px-[2.5rem] sm:text-center">
      <p className="uppercase">our values</p>
      <h3 className="mt-[1rem] mb-[5rem] font-bold text-[3.5rem] sm:text-[3rem]">
        The Core Of Everything We Do
      </h3>
      <div className="grid grid-cols-core-values-grid gap-[2rem] text-[#616161] w-full">
        {valuesData.map((value, i) => (
          <div className="flex flex-col items-center border border-color-border p-[1.5rem] rounded-lg text-center hover:shadow-core-value transition-shadow duration-200 ease-in">
            <div className="my-[2rem] w-[8.5rem] h-[8.5rem] flex items-center justify-center bg-[rgba(81,93,195,0.1)] rounded-lg">
              <img src={value.image} alt={value.title} />
            </div>
            <p className="text-[2rem] font-semibold my-[1rem]">{value.title}</p>
            <p>{value.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CoreValues;
