import React from "react";
import feaIcon1 from "../../assets/paperlx/fea-icon1.svg";
import feaIcon2 from "../../assets/paperlx/fea-icon2.svg";
import feaIcon3 from "../../assets/paperlx/fea-icon3.svg";
import feaImg1 from "../../assets/paperlx/27.png";
// import feaImg2 from "../../assets/paperlx/feature-img3.png";
import shape1 from "../../assets/paperlx/next-gen-shape1.svg";
import shape2 from "../../assets/paperlx/next-gen-shape2.svg";

const texts = [
  {
    img: feaIcon1,
    title: "Protect Documents from Loss and Damage",
    text: "Store important documents securely, protecting them from loss or damage and providing access from any internet enabled device.",
  },
  {
    img: feaIcon2,
    title: "Prevent Fraud and Forgery",
    text: "Immutable properties provide tamper-proof protection, preventing fraud and forgery, and ensuring the authenticity of digital documents.",
  },
  {
    img: feaIcon3,
    title: "Increase Efficiency and Productivity",
    text: "Digital system reduces costs associated with paper documents, while increasing efficiency and productivity by enabling quick verification and instant sharing.",
  },
];

const NextGenSec = () => {
  return (
    <section className="p-[8rem] my-[5rem] xmd:px-[5rem] sm:px-[2.5rem]">
      <h3 className="text-[4rem] font-bold mb-[5rem] sm:text-[3rem] sm:text-center">
        The Next Generation of Document Management
      </h3>
      <div className="flex items-center xmd:flex-col justify-between ">
        <div className="flex flex-col w-[40%] xmd:w-full">
          {texts.map((item, i) => (
            <div className="flex items-start mb-[3rem] last:mb-0 " key={i}>
              <img src={item.img} alt={item.title} className="mr-[2rem]" />
              <div>
                <p className="font-semibold text-[2.2rem] mb-[.5rem]">
                  {item.title}
                </p>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-[45%] flex items-center relative xmd:w-[80%] xmd:justify-center xmd:mx-auto xmd:mt-[5rem]">
          <img src={feaImg1} alt="fea-img" className="shadow-next-gen z-20 " />
          <img
            src={shape1}
            alt="shape1"
            className="absolute -bottom-[4rem] -left-[2.5rem] z-10 xmd:left-[3rem]"
          />
          <img
            src={shape2}
            alt="shape2"
            className="absolute -top-[1rem] right-[45%] animate-spin xmd:right-[30%] sm:-top-[2rem] "
          />
        </div>
      </div>
    </section>
  );
};

export default NextGenSec;
