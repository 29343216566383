import React, { useContext } from "react";
import ctx from "../../store";
import appmockup from "../../assets/spotlx/app-mockup-3.svg";

const SecondSection = () => {
  const { fixedHeader } = useContext(ctx);

  const howItWorks = [
    {
      id: 1,
      title: "Add clothes to Basket",
      text: "Add the clothes you want to wash or iron to the basket while selecting the category they belong to.",
    },
    {
      id: 2,
      title: "Request a rider",
      text: "Choose your pickup and drop off location and the kind of delivery that meets your need.",
    },
    {
      id: 3,
      title: "Make Payments",
      text: "Make payment for the clothes you added to the basket using your preferred method of payment available in our app.",
    },
    {
      id: 4,
      title: "Enjoy our Services",
      text: "Relax and enjoy how swift and perfect our services are",
    },
  ];

  return (
    <section
      className={`flex flex-col text-[1.8rem]  w-full  ${
        fixedHeader
          ? "mt-[100vh] smd:mt-[120vh] ssm:mt-[150vh]"
          : "mt-[calc(100vh-7.5rem)] smd:mt-[calc(120vh-7.5rem)] ssm:mt-[calc(150vh-7.5rem)]"
      }  w-full  bg-color-white px-[5rem] py-[10rem] items-center smd:px-[3rem] smd:py-[5rem] sm:py-[3rem]`}
    >
      <h2 className="text-[4rem] mb-[1.5rem] font-medium smd:text-center">
        How Spotlx Works
      </h2>
      <p className="text-[1.6rem] text-center w-[80%] xlg:w-full">
        Download Spotlx app from playstore, create account, get our rider to
        come pick up your clothes to get them washed, ironed and delivered back
        to you in time with our finest package.
      </p>
      <div className="flex mt-[5rem] w-[90%] xlg:w-full xlg:flex-col">
        <div className="flex flex-col flex-1 justify-evenly text-right xlg:flex-row  xlg:mb-[3rem] sm:flex-col">
          {howItWorks.slice(0, 2).map((item) => (
            <div
              key={item.id}
              className=" flex flex-col items-end xlg:items-center xlg:text-center xlg:border xlg:rounded-md xlg:border-color-border xlg:px-[1rem] xlg:py-[2rem] xlg:mr-[2rem] xlg:last:mr-0 xlg:flex-1  sm:mr-0 sm:mb-[2.5rem]"
            >
              <p className=" bg-[#BFDBFE] w-[5rem] h-[5rem] flex items-center justify-center rounded-md text-[#2563EB]">
                {item.id}
              </p>
              <p className="text-[1.8rem] font-medium my-[1rem] capitalize">
                {item.title}
              </p>
              <p className="text-[1.6rem]">{item.text}</p>
            </div>
          ))}
        </div>
        <div className="flex-1  h-[60rem]  mx-[5rem] xlg:h-[30rem]">
          <img src={appmockup} alt="App mockup" className="w-full h-full" />
        </div>
        <div className="flex-1 flex flex-col justify-evenly xlg:flex-row  xlg:mb-[3rem] sm:flex-col">
          {howItWorks.slice(2, 4).map((item) => (
            <div
              key={item.id}
              className=" flex flex-col xlg:items-center xlg:text-center xlg:border xlg:rounded-md xlg:border-color-border xlg:px-[1rem] xlg:py-[2rem] xlg:mr-[2rem] xlg:last:mr-0  xlg:flex-1 sm:mr-0 sm:mb-[2.5rem]"
            >
              <p className=" bg-[#BFDBFE] w-[5rem] h-[5rem] flex items-center justify-center rounded-md text-[#2563EB]">
                {item.id}
              </p>
              <p className="text-[1.8rem] font-medium my-[1rem] capitalize">
                {item.title}
              </p>
              <p className="text-[1.6rem]">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SecondSection;
