import React from "react";
import teamImg from "../../assets/aboutus/team-4.png";

const teamData = [
  {
    image: teamImg,
    name: "nnamdi",
    post: "founder",
  },
  {
    image: teamImg,
    name: "akay",
    post: "co-founder",
  },
  {
    image: teamImg,
    name: "ejim",
    post: "web engineer",
  },
  {
    image: teamImg,
    name: "onyinye",
    post: "product design intern",
  },
  {
    image: teamImg,
    name: "emeka",
    post: "operations manager",
  },
  {
    image: teamImg,
    name: "okwudili",
    post: "software engineer",
  },
  {
    image: teamImg,
    name: "elvis",
    post: "social media manager",
  },
];

const MeetOurTeam = () => {
  return (
    <section className="mt-[12rem] flex- flex-col px-[10rem] xl:px-[6.5rem] xlg:px-[5rem] sm:px-[2.5rem] sm:text-center items-center bg-[#f9f9f9] py-[8rem]">
      <h3 className="mb-[3rem] text-[3.5rem] sm:text-[3rem] font-bold capitalize text-center">
        meet our team
      </h3>
      <div className="flex flex-wrap justify-center">
        {teamData.map((teammate, i) => (
          <div
            className="flex flex-col items-center py-[2.5rem] capitalize mr-[2rem] sm:mr-0 mb-[2rem] rounded-lg bg-color-white w-[30rem] sm:w-full"
            key={i}
          >
            <img
              src={teammate.image}
              alt={teammate.name}
              className="rounded-[50%] mb-[2rem]"
            />
            <p className="my-[.5rem] text-[1.8rem] font-semibold">
              {teammate.name}
            </p>
            <p className="text-[#a0a1a3]">{teammate.post}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MeetOurTeam;
