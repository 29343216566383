import React from "react";
import featureImg from "../../assets/home/39.png";
import featureImg2 from "../../assets/home/42.png";
import { Link } from "react-router-dom";

const SecondSection = ({ containerRef }) => {
  const secondSectList = [
    {
      title: "Facilitating Digital Transformation",
      text: "We empower individuals and organizations with innovative and cutting-edge digital tools that transform traditional systems on a global scale, driving progress and advancement towards a better future",
      btnText: "Learn More",
      btnPath: "/blog",
      image: featureImg,
    },
    {
      title: "Driving Industry Evolution",
      text: "Our secure and advanced tools enable innovation across different sectors by streamlining processes, reducing costs, increasing efficiency and optimizing business Workflows for sustainable growth.",
      btnText: "Get Started",
      btnPath: "/paperlx",
      image: featureImg2,
    },
  ];

  return (
    <section className="mt-[5rem] py-[3rem] px-[6rem] sm:px-[2.5rem]">
      {secondSectList.map((item, i) => (
        <div
          key={i}
          className="flex  mb-[8rem] xmd:flex-col items-center xmd:text-center w-full justify-between"
        >
          <div
            className={`w-[45%]  xl:w-[48%] xmd:w-full xmd:flex xmd:flex-col xmd:items-center xmd:mt-[3rem] ${
              i % 2 === 0 ? "order-1 xmd:order-2" : "order-2"
            }`}
          >
            <img src={item.image} alt={"feature-img"} />
          </div>
          <div
            className={`w-[45%] xl:w-[48%] xmd:w-full  flex flex-col items-start xmd:items-center  ${
              i % 2 === 0 ? "order-2 xmd:order-1" : "order-1"
            }`}
          >
            <h3 className="text-[4.5rem] sm:text-[3rem] font-bold text-color-black">
              {item.title}
            </h3>
            <p className="my-[2rem] text-[1.8rem] xmd:w-[85%] md:w-full">
              {item.text}
            </p>
            <Link
              to={item.btnPath}
              className={`text-color-white bg-color-purple border-color-purple hover:bg-color-white hover:text-color-purple  py-4  px-10 text-center capitalize border  rounded-lg  transition-all duration-200 ease-in  block mt-[1.5rem] `}
            >
              {item.btnText}
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
};

export default SecondSection;
