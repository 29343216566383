import { useForm } from "react-hook-form";
import InputComponent from "../InputComponent";
import { registrationOption } from "../utils/formValidation";
import Button from "../ButtonComponent";
import React, { useState } from "react";
import { toastSuccess, toastError } from "../utils/toastFunctions";
import { user } from "../../axiosconfig";
import ReactLoading from "react-loading";

const ContactUsForm = ({ contactType }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  const submitContactFormHandler = async (data) => {
    setIsLoading(true);
    const info = {
      name: data.name,
      email: data.email,
      message: data.message,
    };
    try {
      const res = await user.post(
        `messages/${contactType}.json`,
        JSON.stringify(info)
      );

      if (res.statusText !== "OK") {
        throw new Error("Something wrong occured!");
      }
      toastSuccess("Message sent successfully");
      reset({ name: "", email: "", message: "" });
      setIsLoading(false);
    } catch (err) {
      toastError("Please try again!");
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitContactFormHandler)}
      className="flex flex-col"
    >
      <InputComponent
        type="text"
        name="name"
        label={"Full Name"}
        id="name"
        inputClassName={`bg-color-white w-full py-[1rem] border-color-black border-2  px-[2rem] rounded-lg outline-none  ring-0 active:bg-color-white ${
          errors["name"] ? "text-color-red" : "text-color-black"
        }`}
        containerClassName={"relative w-full  my-[1.2rem]"}
        errors={errors}
        register={register}
        validation={registrationOption.name}
      />
      <InputComponent
        type="email"
        name="email"
        label={"Email"}
        labelClassName={"text-color-black"}
        id="email"
        inputClassName={`bg-color-white w-full py-[1rem] border-color-black border-2 px-[2rem] rounded-lg outline-none  ring-0 active:bg-color-white ${
          errors["email"] ? "text-color-red" : "text-color-black"
        }`}
        containerClassName={"relative w-full rounded-lg my-[1.2rem]"}
        errors={errors}
        register={register}
        validation={registrationOption.email}
      />
      <div className="flex flex-col w-full">
        <label htmlFor="message" className="mb-[1rem]">
          Message
        </label>
        <textarea
          rows={4}
          id="message"
          className="border-2 border-color-black rounded-lg px-[2rem] py-[1rem] outline-none  ring-0 active:bg-color-white "
          name="message"
          {...register("message", registrationOption.message)}
        />
        {errors && (
          <small className={`text-color-red `}>
            {errors["message"] && errors["message"].message}
          </small>
        )}
      </div>
      <Button
        className={
          "py-[1.5rem] text-[1.8rem] border-2  mt-[5rem] w-full text-color-white bg-color-purple hover:text-color-purple hover:bg-color-white transition-all duration-200 ease-in"
        }
      >
        {isLoading ? (
          <ReactLoading
            type="bubbles"
            color="#660066"
            width={30}
            height={30}
            className="mx-auto"
          />
        ) : (
          "send message"
        )}
      </Button>
    </form>
  );
};

export default ContactUsForm;
