import { createContext } from "react";
import { useState, useCallback } from "react";
import blogPostsData from "./components/utils/BlogPostsData";

const ctx = createContext({
  fixedHeader: false,
  setFixedHeader: () => {},
  blogPostsList: [],
  blogCategory: "",
  setBlogCategory: () => {},
  filterPostsByCategory: () => {},
});

export const ContextProvider = ({ children }) => {
  const [fixedHeader, setFixedHeader] = useState(false);
  const [blogCategory, setBlogCategory] = useState("");
  const [postsList, setPostsList] = useState(blogPostsData);

  // const filterPostsByCategory = (category) => {
  //   if (category) {
  //     const filteredPosts = postsList.filter(
  //       (post) => post.tag.toLowerCase() === category.toLowerCase()
  //     );
  //     setPostsList(filteredPosts);
  //   } else {
  //     setPostsList(blogPostsData);
  //   }
  // };

  return (
    <ctx.Provider
      value={{
        fixedHeader,
        setFixedHeader,
        blogPostsList: postsList,
        // filterPostsByCategory,
        blogCategory,
        setBlogCategory,
      }}
    >
      {children}
    </ctx.Provider>
  );
};

export default ctx;
