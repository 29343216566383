import React, { useContext } from "react";
import ctx from "../../store";
import BlogPostList from "./BlogPostList";
import SubscribeToBlogComp from "./SubscribeToBlogComp";
import MediaFollowers from "./MediaFollowers";
import PostCategories from "./PostCategories";
import RecentPosts from "./RecentPosts";
import BlogTags from "./BlogTags";
import BlogInstagramPics from "./BlogInstagramPics";

const InfoSection = () => {
  const { fixedHeader } = useContext(ctx);

  return (
    <section
      className={` w-full flex justify-between items-start bg-[#ebebeb] min-h-[50vh] xmd:flex-wrap  px-[10rem] xl:px-[5rem] py-[12rem] smd:py-[8rem] sm:px-[2.5rem] ${
        fixedHeader ? "mt-[100vh]" : "mt-[calc(100vh-7.5rem)]"
      } `}
    >
      <div className="w-[60%] xl:w-[65%] xmd:w-full">
        <BlogPostList />
      </div>
      <div className="w-[30%] xmd:w-full xmd:mt-[5rem] xmd:grid xmd:grid-cols-blog-grid xmd:gap-[3rem]">
        {/* <div className="xmd:order-3"> */}
        {/* <SubscribeToBlogComp /> */}
        {/* <MediaFollowers /> */}
        {/* </div> */}
        {/* <PostCategories /> */}
        {/* <RecentPosts /> */}
        {/* <BlogTags /> */}
        <BlogInstagramPics />
      </div>
    </section>
  );
};

export default InfoSection;
