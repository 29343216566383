import React from "react";
import FirstSectionCard from "../FirstSectionCard";
import Appdownloadbtn from "../appdownloadbtn";
import googleplayIcon from "../../assets/spotlx/googleplayIcon.svg";
import appleIcon from "../../assets/spotlx/AppleIcon.svg";
import appmockup from "../../assets/spotlx/app-mockup-hero.svg";
import appmockup2 from "../../assets/spotlx/app-mockup-hero-2.svg";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

const IntroSection = () => {
  return (
    <FirstSectionCard className="bg-spotlx bg-fixed  bg-no-repeat bg-cover bg-center  h-screen absolute top-0 z-10  px-[5rem]  justify-between xlg:flex-col xlg:justify-center rounded-bl-md rounded-br-md smd:px-[3rem]  smd:h-[120vh] ssm:h-[150vh]">
      <div className="w-[45%] xlg:w-full xlg:flex xlg:flex-col xlg:items-center xlg:text-center">
        <h1 className="text-[4.5rem] font-medium smd:text-[3rem]">
          Download app, Start washing, Remain clean!
        </h1>
        <p className="my-[2rem] text-[1.6rem]">
          Download Spotlx app from playstore, create account, get our rider to
          come pick up your clothes to get them washed, ironed and delivered
          back to you in time with our finest package.
        </p>
        <div className=" flex sm:w-full sm:justify-between">
          <Appdownloadbtn text="google play" icon={googleplayIcon} link="https://play.google.com/store/apps/details?id=com.akayudeh.Spotlx" />
          <Appdownloadbtn text="app store" icon={appleIcon} link="" />
        </div>
      </div>
      <div className="w-[45%] xlg:w-full xlg:mt-[3rem]">
        <Swiper // install Swiper modules
          modules={[Navigation, Pagination, EffectFade, Autoplay]}
          // spaceBetween={50}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 4000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
          effect="fade"
          loop
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          className="w-full h-full"
        >
          <SwiperSlide>
            {({ isActive }) =>
              isActive ? (
                <div className="">
                  <img
                    alt="App Mockup"
                    src={appmockup}
                    className="w-full h-[50rem]"
                  />
                </div>
              ) : (
                <div className="bg-color-transparent"></div>
              )
            }
          </SwiperSlide>
          <SwiperSlide>
            {({ isActive }) =>
              isActive ? (
                <div className="">
                  <img
                    alt="App Mockup"
                    src={appmockup2}
                    className="w-full h-[50rem]"
                  />
                </div>
              ) : (
                <div className="bg-color-transparent"></div>
              )
            }
          </SwiperSlide>
        </Swiper>
      </div>
    </FirstSectionCard>
  );
};

export default IntroSection;
