import React from "react";
import featuresImg from "../../assets/home/32.png";
import icon11 from "../../assets/home/icon11.svg";
import icon12 from "../../assets/home/icon12.svg";
import icon13 from "../../assets/home/icon13.svg";
import borderImg from "../../assets/home/border.svg";

const info = [
  {
    title: "Paperless Documents",
    text: "Create Durable, Secure, and Verifiable Digital Documents to Mitigate Forgery, Loss, and Damage Risks",
    image: icon11,
  },
  {
    title: "Universal Document Verification",
    text: "Empowering Users with Reliable and Universal Document Verification: Streamlining Verification Processes and Reducing Fraud Risk",
    image: icon12,
  },
  {
    title: "Instant Document Sharing",
    text: "Access, Share and manage authentic documents instantly and efficiently from anywhere",
    image: icon13,
  },
];

const FourthSection = () => {
  return (
    <section className="my-[10rem] px-[5rem] lg:px-[2.5rem] w-full flex justify-center">
      <div className="w-full lg:px-[10rem] sm:px-0 flex items-center">
        <img
          src={featuresImg}
          alt="features-img"
          className="mr-[7rem] lg:mr-[3rem] xmd:hidden w-[40%]"
        />
        <div className="flex-1">
          <p className="text-[4rem] sm:text-[3rem]  font-bold mb-[3.5rem] smd:text-center">
            Authentic Digital Documents With{" "}
            <span className="relative  ">
              Paperlx
              <img
                src={borderImg}
                alt="border-img"
                className="absolute -bottom-[1rem] right-0 w-full -z-10"
              />
            </span>
          </p>
          <div>
            {info.map((item, i) => (
              <div
                key={i}
                className="flex items-center mb-[2rem] smd:mb-[4rem]"
              >
                <div className="rounded-[50%] w-[8rem] h-[8rem] flex justify-center items-center shadow-md mr-[2rem]">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-[4rem] h-[4rem]"
                  />
                </div>
                <div className="w-[80%]">
                  <p className="font-semibold text-color-black text-[2rem]">
                    {item.title}
                  </p>
                  <p className="text-[#5d5d66] ">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FourthSection;
