import React, {useEffect} from "react";
import IntroSection from "../components/Blog components/IntroSection";
import InfoSection from "../components/Blog components/InfoSection";

const Blog = () => {
 useEffect(() => {
   window.scrollTo(0, 0);
 }, []);

  return (
    <>
      <IntroSection />
      <InfoSection />
    </>
  );
};

export default Blog;
