import React from "react";

const VideoSection = ({ videoClass }) => {
  return (
    <section className="w-full flex justify-center my-[6rem]">
      <iframe
        className={`${videoClass}`}
        src="https://www.youtube.com/embed/0tZFQs7qBfQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </section>
  );
};

export default VideoSection;
